.inq-home-about-st h5::after {
    content: "";
      display: block;
      height: 5px;
      background: $primary;
      width: 50px;
      position: absolute;
      top: 2.75em;
      left: 0;
      right: 0;
      transition: transform ease-in-out 250ms;
      margin: 0 auto;
  
  }
  
  .home-page{
    .card{
      border:none;
    }
  }
  
  .partners_img{
    // box-shadow: 0 0 7px gray;
    // border-radius: 5px; for bombayhardware change
    border: 1px solid hsl(0, 0%, 90%);
    height: 100%;
    width: 100%;
    // margin-bottom: 15px;
    transition: all 0.3s ease;
    
  }
  .partners_img:hover{
    // box-shadow: 0 0 27px #d2b56f;
    // transform: scale(1.1);
  }
  // .partners_img .jsw_style{
  //   padding: 54px 20px;
  // }
  
  @media screen and (min-width:576px) and (max-width:767px ) {
      // .inq-home-about-st h5::after {
      //     top: 12.75em;
      //     left: 45%;
      // }
      .inq-title-st{
        margin: 0 0 0 13px;
      
      }
  }
  
  @media screen and (max-width:480px ) {
      // .inq-home-about-st h5::after {
      //     top: 10.25em;
      // }
      #innerpartFront {
          padding: 1rem 0rem !important;
  }
  }
  
  
  @media screen and (min-width:768px) and (max-width:1000px ) {
      // .inq-home-about-st h5::after {
      //     top: 6.75em;
      //     left: 35%;
      // }
  }
  @media screen and  (min-width:1900px) {
    #innerpart {
      height: 350px !important;
    }
  }
  
  
  
  .carouselStyling{
    top: 50%;
    left: 50%;
    min-width: 100%;
    height: 100%;
  }
  
  
  .cardBox {
      float: left;
      font-size: 1.2em;
      margin: 1% 0 0 1%;
      perspective: 800px;
      transition: all 0.3s ease 0s;
      width: 89%;
    }
  //   .card {
  //     background:  url(../../assets/images/vendor/dumm/du1/Prorect.png) center center no-repeat;
  //   }
    
    .cardBox:hover .card {
      @if $product-cd != "tams"{
        transform: rotateY( 180deg);
      }
    }

    .cardBox:hover{
      @if $product-cd != "tams"{
        .back{
          z-index: 99;
        }
        .front{
          z-index: 1;
          display: none;
        }
      }
      
    }
 
    .cardBox:not(:hover){
      @if $product-cd != "tams"{
        .back{
          z-index: 0;
        }
        .front{
          z-index: 99;
        }
      }
    }
    
    @media screen and (min-width: 1200px) {
      .card {
        height: 420px;
      }
      .cardBox {
          width: 89%;
      }
      #innerpartFront {
          padding: 2.5rem 0rem;
  }
    }
    .card {
      background: transparent !important;
      cursor: default;
      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      width: 100%;
       -webkit-animation: giro 1s 1;
       animation: giro 1s 1; border: none;
    }

    .havencard {
      background: transparent !important;
      cursor: default;
      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      width: 100%;
      //  -webkit-animation: giro 1s 1;
      //  animation: giro 1s 1; border: none;
    }

    .cardtext {
      background: transparent !important;
      cursor: default;
      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      width: 80%;
      border: 1px solid rgba(0, 0, 0, 0.125);
       border-radius: 0.25rem;
      //  -webkit-animation: giro 1s 1;
      //  animation: giro 1s 1; border: none;
    }

    .cardtext1 {
      background: transparent !important;
      cursor: default;
      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      width: 80%;
      border: 1px solid rgba(0, 0, 0, 0.125);
       border-radius: 0.25rem;
      //  -webkit-animation: giro 1s 1;
      //  animation: giro 1s 1; border: none;
    }
    
    #innerpart {
        background-color: #EDE8CA;
        border-radius: 30px;
        padding: 4rem 0rem;
        .inq-cart-bw-st{
          border:none !important;
          background-color: transparent !important;
          i{
            background-color: transparent;
          }
        }
    }
   
    #innerpartFront {
      background-color: #E8E8E8;
      border-radius: 30px;
      padding: 2.5rem 0rem;
  }
   #innerpartFront img {
      max-width: 100%;
      padding: 0.5rem;
   }
    .front p {
        color: #323232 !important;
    }
    .front h5 {
      color: #323232 !important;
      font-size: 17.5px;
     
  }
  .checkout {
    color: #FFFCF8;
      font-size: 13px;
      position: absolute;
      padding: 11px 22px;
      text-transform: uppercase;
      text-decoration: none;
      top:28%;
      left: 28%;
      transform: translate(-28% , -28% );
      border-radius: 28px;
      background: #805245;
      border: 1px solid #805245;
      font-weight: 600;
      transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      margin: 0;
  }
  .faq-padding{
    padding: 30px;
    padding-left: 18%;
  }
  .sizechart-padding{
    padding: 40px 80px;
  }
  @media screen and (max-width:786px) {
    .faq-padding{
      padding: 30px;
      padding-left: 30px;
      width: 100%;
    }
    .cardtext1{
      width: 100%;
    }
    .sizechart-padding{
      padding: 40px 20px;
    }
  }
  @media screen and (max-width:480px) {
    .checkout {
      padding: 6px 11px;
      font-size: 10px;
      position: relative;
      bottom: 3px;
      left: 11%;
    }
  }
  .checkout a {
    text-decoration: none;
    color: #FFFCF8
  }
    .card .front {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: white;
      display: block;
      height: 100%;
      padding:0;
      
      position: absolute;
      text-align: center;
      width: 100%
    }

    .havencard .front {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: white;
      display: block;
      height: 100%;
      padding:0;
      
      position: absolute;
      text-align: center;
      width: 100%
    }
    
   
    @media screen and  (min-width:1200px) {
      #innerpart {
        height: 350px !important;
      }
      #innerpartFront {
        height: 350px !important;
      }
    }
    .card .back {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: white;
      display: block;
      font-size: 1.2em;
      padding:0;
      position: absolute;
      text-align: center;
      width: 100%;
      transform: perspective(200px) rotateY( 180deg);
    }
    .havencard .back {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: white;
      display: block;
      font-size: 1.2em;
      padding:0;
      position: absolute;
      text-align: center;
      width: 100%;
      transform: perspective(200px) rotateY( 180deg);
    }
    .card .front strong {
      background: #fff;
      border-radius: 100%;
      color: #222;
      font-size: 1.5em;
      line-height: 30px;
      padding: 0 7px 4px 6px;
    }
    .havencard .front strong {
      background: #fff;
      border-radius: 100%;
      color: #222;
      font-size: 1.5em;
      line-height: 30px;
      padding: 0 7px 4px 6px;
    }
    
    
    .card .back button {
      background: $pallet2 !important;
      border: 1px solid  $pallet2 !important;
      border-radius: 20px !important;
      color: white;
      padding: 0.375rem 1.5rem !important;
    }

    .havencard .back button {
      background: $pallet2 !important;
      border: 1px solid  $pallet2 !important;
      border-radius: 20px !important;
      color: white;
      padding: 0.375rem 1.5rem !important;
    }
    
    /* .card .back a:hover {
      background: #fff;
      color: #333;
      text-shadow: 0 0 1px #333;
    } */
    
    .textblack{
      color: #333 !important;
      font-size: 14px;
      line-height: 1.7;
      padding-left: 6px;
      padding-right: 6px;
    }
    .textblack1 {
        font-size: 18px;
        color: #333 !important;
    }
    .textbrrown {
        color: $pallet2;
        font-weight: bold;
    }
    .cardBox:nth-child(2) .card {
      -webkit-animation: giro 1.5s 1;
      animation: giro 1.5s 1;
    }
    
    .cardBox:nth-child(3) .card {
      -webkit-animation: giro 2s 1;
      animation: giro 2s 1;
    }
    
    .cardBox:nth-child(4) .card {
      -webkit-animation: giro 2.5s 1;
      animation: giro 2.5s 1;
    }
    
    @-webkit-keyframes giro {
      from {
        transform: rotateY( 180deg);
      }
      to {
        transform: rotateY( 0deg);
      }
    }
    
    @keyframes giro {
      from {
        transform: rotateY( 180deg);
      }
      to {
        transform: rotateY( 0deg);
      }
    }
    
    @media screen and (max-width: 768px) {
      .cardBox {
        margin-left: 2.8%;
        margin-top: 3%;
        width: 90%;
      }
      .card {
        height: 385px;
      }
      .havencard {
        height: 385px;
      }
      .cardBox:last-child {
        margin-bottom: 3%;
      }
    }
    @media screen and (max-width: 1199px) and (min-width:769px) {
      .card {
        height: 385px;
      }
      .havencard {
        height: 385px;
      }
      #innerpart{
        padding: 10px;
      }
    }
    @media screen and (max-width: 480px) {
      .cardBox {
        width: 94.5%;
      }
      .card {
        height: 345px;
      }
      .havencard {
        height: 345px;
      }
    }
  
    .innerpart {
        padding: 30px;
    }
  
  .bag {
    left: 17%;
  }
  
  .static-image{
    right:0%;
    margin-top:75px;
  }
  
  .rounder-corner-nav .owl-theme .owl-nav {
    margin-top: 0px;
  }
  
  
  element.style {
  }
  .rounder-corner-nav .owl-theme .owl-nav {
      margin-top: 0px;
      margin-left: 2%;
  }
  .owl-theme .owl-nav {
      //margin-top: 10px;
      text-align: center;
      -webkit-tap-highlight-color: transparent;
  }
  
  .rounder-corner-nav .owl-theme .owl-nav .owl-prev, .rounder-corner-nav .owl-theme .owl-nav .owl-next {
    border-radius: 20px;
    width: 40px;
    height: 40px;
    // background: transparent;
    border: 2px solid #b7b6b5;
  }
  .inq-sp-1 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;
  }
  
  
  .inq-sp-3 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;
  
  }
  .inq-sp-1 i:hover,.inq-sp-3 i:hover{
    background: $pallet2;
    color: #fff;
    cursor: pointer;   
  }
  
  .quantity-btn{
    background-color: $primary;
    color: #ffffff;
    margin-left: 20%;
    width: 60%;
    border-radius: 10px;
    i{
      background: $primary;
      color:#ffffff;
    }
    .inq-sp-1 i:hover,.inq-sp-3 i:hover{
      background: $pallet2;
      color: #fff;
      cursor: pointer;   
    }
  }
  
  .tabs-vim {
    position: relative;
    margin: 3rem 0;
    background: white;
    display: flex;
    justify-content: center;
    height: 2.75rem;
  }
  .tabs-vim::before,
  .tabs-vim::after {
    content: "";
    display: table;
  }
  .tabs-vim::after {
    clear: both;
  }
  .tab-vim {
    float: left;
  }
  .tab-switch-vim {
    display: none;
  }
  .tab-label-vim {
    position: relative;
    display: block;
    line-height: 2.75em;
    height: 3em;
    padding: 0 1.618em;
    background: white;
    border-right: 0.125rem solid black;
    color: black;
    cursor: pointer;
    top: 0;
    transition: all 0.25s;
  }
  .tab-label-vim:hover {
    top: -0.25rem;
    transition: top 0.25s;
    color: #b73341;
  }
  .tab-content-vim {
    position: absolute;
    z-index: unset;
    left: 0;
    padding: 1.618rem;
    background: #fff;
    color: #2c3e50;
    border-bottom: 0.25rem solid #bdc3c7;
    opacity: 0;
    transition: all 0.35s;
  }
  .tab-switch-vim:checked + .tab-label-vim {
    color: $secondary;
    border-bottom: 0;
    transition: all 0.35s;
    z-index: unset;
    top: -0.0625rem;
    
  }
  .tab-switch-vim:checked + label + .tab-content-vim {
    z-index: unset;
    opacity: 1;
    transition: all 0.35s;
    width: 100%;
  }
  // PRODUCT CARD HANDLING
  .product-card{
    @if $product-cd == "tams" {
        visibility: hidden;
    }
    @else if $product-cd == "earthyworthy"
    {
      display: none;
    }
    @else{
        visibility: visible;
    }
  }

  .tams-card{
    @if $product-cd == "tams"{
      visibility: visible;
      // background-color: $white;
      border-radius: 15px;
      height: 350px !important;
      border:3px solid #f3f2f2;
      .card-img-top{
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
      }
      .btn{
        border-radius: 0px !important;
      }
      // TAMS PRODUCT CARD
  .add-cart-btn{
    background-color: $primary;
    font-size: .9rem;
    padding: 5px 15px;
    color: $white;
    margin-top: 10px;
  }

  .added-to-cart
  {
    background-color: $primary;
    color : $white;
    width: 90px !important;
    border-radius: 5px;
    text-align: center;
    margin: auto !important;
    padding: 3px 0px;

    i:hover
    {
      cursor: pointer;
    }
  }

  .add-cart-btn2{
    background-color: $primary;
    font-size: .9rem;
    padding: 5px 15px;
    color: $white;
    margin-top: 10px;
    pointer-events: none;
  }

  .add-cart-btn:hover{
    font-weight: 400;
    color: $white;
  }
  }@else{
      display: none;
  }
  }

  

  .online-image{
    @if $product-cd == "tams"{
      width: 190px;
      height: 190px;
    }
    @else {
      width: 0px;
      height: 0px;
    }
  }

  .online-image1{
    @if $product-cd == "tams"{
      width: 0px;
      height: 0px;
    }
    @else {
      width: 190px;
      height: 190px;
    }
  }

  .murugesan-card{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    min-height: 353px !important;
    max-height: 353px !important;
    border-radius: 5px;
    z-index: 99 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    transition: min-height 0.4s;
    transition-timing-function: ease;
  }

  .murugesan-card:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    .murugasen-list-image{
      min-height: 205px !important;
      max-height: 205px !important;
      width: 100% !important;
    }
    min-height: 358px !important;
    max-height: 358px !important;
  }

  .murugesan-card::before{
    position: absolute;
    top:2rem;
    right:-0.5rem;
    content: '';
    background: #e9343d;
    height: 28px;
    width: 28px;
    transform: rotate(45deg);
    z-index: -1 !important;
  }

  .murugesan-card::after{
    position: absolute;
    content: attr(data-label);
    top:16px;
    right: -14px;
    padding: 3px;
    width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    background: #e9343d;
    color: white;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .murugesan-card-title{
    font-size: 17px;
    color: rgb(34, 34, 34);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
  }

  .murugesan-card-title1{
    font-size: 17px;
    color: rgb(34, 34, 34);
    font-weight: 400;
  }

  .murugesan-description{
    padding:10px 10px;
    padding-bottom: 0px !important;
  }

  .murugasen-list-image{
    min-height: 200px !important;
    max-height: 200px !important;
    width: 100% !important;
    border-radius: 5px !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }

  .murugesan-price{
    padding-top: 3px;
    font-size: 25px;
    color: #000;
    font-weight: 500;
  }

  .murugesan-addcart{
    // position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 5px 0px; 
    border:none;
    background-color: #e9343d;
    color: white;
  }

  .murugesan-card1{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    min-height: 353px !important;
    max-height: 353px !important;
    border-radius: 5px;
    z-index: 99 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    transition: min-height 0.4s;
    transition-timing-function: ease;
  }

  .murugesan-card1:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    .murugasen-list-image{
      min-height: 205px !important;
      max-height: 205px !important;
      width: 100% !important;
    }
    min-height: 358px !important;
    max-height: 358px !important;
  }

  .img-with-text{
    text-align: center;
    width: 100%;
  }

  .img-with-text img{
    display: block;
    margin: 0 auto;
    position: relative;
  }

  //carousel
  .testimonial {
    padding: 80px 0;
  }
  .sec-heading {
    margin-bottom: 60px;
  }
  .sec-heading h6 {
    font-family: allura;
    font-weight: 900;
    font-size: 80px;
  }
  .single-box {
    border: 3px solid #e6f0fa;
    padding: 50px 30px 40px;
    border-radius: 20px;
    background: #fff;
  }
  .img-area {
    margin: 45px 0 20px;
  }
  .single-box img {
    max-width: 100px;
    border-radius: 50%;
    margin: 0 auto
  }
  .single-box h4 {
    font-weight: 600;
    margin: 0;
    font-family: 'Allura', cursive;
    font-size: 35px;
  }
  .single-box {
    overflow: hidden
  }
  .single-box .img-area {
    width: 30%;
    float: left;
    margin: 0
  }
  .single-box .content {
    width: 70%;
    float: left;
    padding-left: 10px
  }
  .single-box p {
    margin: 10px 0 25px;
    line-height: 2.3;
  }
  .testi-carousel-three .single-box {
    border: 0;
    padding: 0 50px;
  }
  .testi-carousel .owl-dots, .clients-carousel .owl-dots, .testi-carousel-three .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -60px;
    text-align: center;
    width: 100%;
  }
  .testi-carousel .owl-dot, .clients-carousel .owl-dot, .testi-carousel-three .owl-dot {
    width: 16px;
    height: 16px;
    background-color: #ddd;
    display: inline-block;
    margin: 0 6px;
    text-align: center;
    border-radius: 50%;
  }
  .testi-carousel .owl-dot.active, .clients-carousel .owl-dot.active, .testi-carousel-three .owl-dot.active {
    background-color: blueviolet;
  }
  @media only screen and (min-width: 360px) and (max-width: 479px) {
    .testimonial {
      padding: 70px 0 130px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    .testimonial {
      padding: 70px 0 130px;
    }
    .single-box {
      padding: 50px 0 40px;
    }
    .single-box .img-area {
      width: 100%;
      float: none;
    }
    .single-box .content {
      width: 100%;
      float: none;
    }
  }


  @media (max-width: 1920px)  {
    .bestgifts-arrival{
      justify-content: center;
   }
   
  }

  @media (min-width: 360px) and (max-width: 600px) {
    .mayan_ftre_name {
      margin-left: 0px;
  }
    .bestgifts-arrival{
      justify-content: center;
   }
  }
  .home-card{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    
  }
  .text-centre{
    border: 1px solid $primary;
  }
  .card-heading2{
    height: 50px;
    background-color: #1e1e73;
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
    font-size: 14px !important;
  }
  .card-heading{
    height: 50px;
    background-color: #1e1e73;
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
    font-size: 14px !important;
  }
  .home-container{
    display:grid;
    grid-template-columns: repeat(6, 1fr);
  }
  .home-container3{
    display:grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .home-container7{
    display:grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .home-container4{
    display:grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .home-container5{
    display:grid;
    height: 300px;
    grid-template-columns: repeat(6, 1fr);
  }
  .home-container2{
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 5%;
  }
  .home-containeravon{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 5%;
  }
  @media screen and (max-width:600px) {
    .resp_bt{
      width: 50%;
    }
    .display_flex{
      margin-top: 6px!important;
    }
    .home-container{
      grid-template-columns: repeat(2, 1fr);
    }
    .home-container3{
      grid-template-columns: repeat(2, 1fr);
    }
    .home-container7{
      grid-template-columns: repeat(2, 1fr);
    }
    .home-container4{
      grid-template-columns: repeat(2, 1fr);
    }
    .home-container5{
      margin-left: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
    .home-container2{
      grid-template-columns: repeat(2, 1fr);
    }
    .home-containeravon{
      grid-template-columns: repeat(2, 1fr);
    }
    .card-heading{
      font-size:12px !important
    }
    .card-heading2{
      font-size:12px !important
    }
  }






  .isempty:empty{
    display: none;
}

  .isempty:empty{
    display: none;
}
@media only screen  and (min-width: 1300px)  {
  .owl-theme .owl-nav {
    //margin-top: 10px;
    position: relative;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
}

  // @media screen and (max-width: 360px) {
  //   .bestgifts-arrival{
  //     justify-content: left;
  //  }
   
  // }

  // .murugesan-card1::before{
  //   position: absolute;
  //   top:1.7rem;
  //   right:-0.4rem;
  //   content: '';
  //   background: #e9343d;
  //   height: 28px;
  //   width: 28px;
  //   transform: rotate(45deg);
  //   z-index: 1 !important;
  // }

  // .murugesan-card1::after{
  //   position: absolute;
  //   content: attr(data-label);
  //   top:16px;
  //   right: -13px;
  //   padding: 3px;
  //   width: 8rem;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   flex-wrap: nowrap;
  //   background: #e9343d;
  //   color: white;
  //   text-align: center;
  //   box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !important;
  //   border-top-left-radius: 10px;
  //   border-bottom-left-radius: 10px;
  //   z-index: 99 !important;
  // }
  .display_flex{
    display: flex;
  }