.inq-foot-links-st{
    .pointer {cursor: pointer;}
    .pointer:hover{
        font-weight: bold;
    }
    a{
        color:$white !important
    }
}
.poweredbylogo{
	position: relative;
	top: 58px;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;

}
.pwrdby{
	position: relative;top: 79px;right: 100px;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;

}

.poweredbylogo1{
	position: relative;
	top: 58px;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;

}
.pwrdby1{
	position: relative;top: 70px;right: 100px;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;

}
.pwrdby2{
	position: relative;top: 70px;right: 100px;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;

}
@media (min-width:1901px){
    .pwrdby1{
        position: relative;top:73px;right: 100px;
        width:8%;
        float: right;
        margin-top: -3.5%;
        margin-right: 5%;
        object-fit: contain;
    
    }

    .poweredbylogo1{
        position: relative;
        top: 77px;
        width: 7%;
        float: right;
        margin-top: -4%;
        margin-right: 5%;
        object-fit: contain;
    }
}
@media screen and (max-width: 600px) {
	.poweredbylogo{
		position: relative;
		height: 70;
		top: 100px;
		right: 90px;
		min-width: 22%;
		min-height: 20%;
        width: 16%;
        margin-top: -13%;
        margin-right: 0%;
	}
	.pwrdby{
		position: relative;
        top: 99px;
        white-space: nowrap;
        right: 196px;
        width: 16%;
        margin-top: -13%;
        margin-right: 0%;
	}
    .poweredbylogo1{
		position: relative;
		height: 70;
		top: 90px;
		right: 90px;
		min-width: 22%;
		min-height: 20%;
        width: 16%;
        margin-top: -26%;
        margin-right: 0%;
	}
	.pwrdby1{
		position: relative;
        top: 45px;
        white-space: nowrap;
        right: 196px;
        width: 16%;
        margin-top: -10%;
        margin-right: 0%;
	}
    .pwrdby2{
		position: relative;
        top: 45px;
        white-space: nowrap;
        right: 196px;
        width: 16%;
        margin-top: -13%;
        margin-right: 0%;
	}
}
