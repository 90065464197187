
::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}
a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}
ul {
	padding-left: 0px;
	margin: 0;
}
li {
	list-style: none;
}

h3,
p {
	// font-family: "Inter-Regular";
}

.product-name {
//   font-family: 'proxima-nova';
}
h6 {
	// font-family: 'proxima-nova';
}
h4 {
	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}
	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}
.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
  }
/** Title **/
.content-title {
	text-align: center;
	padding: 60px 0px 30px 0px;
	.title-box {
		.title {
			display:inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #FBCB0C;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}
		.sub-title {
			// font-family: 'Inter-Regular';
			color:#a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}
.sub-heading{
	text-transform: uppercase;
    font-size: 17px;
    // font-family: 'Inter-Medium';
    margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding:10px;
		position: relative;
		border: 2px solid #eceaea;
		.product-name {
			padding-right:30px;
		}
		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}
/**product**/

.product-name {
	font-size: 18px;
}
h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
    .collection-content {
      background-color: #fff;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    .collection-top {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .collection-filter {
      background-color: #fff;
      .filter-top {
        border-bottom: 1px solid #dddddd;
        .filter-clear {
          padding: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .layerd-navigation {
        padding: 10px;
        // font-family: "Inter-Regular";
		border-bottom: 1px solid #dddddd;
		.collection-collapse-block {
			margin-bottom: 10px;
			
		.collection-collapse-block-content {
			padding-left: 30px;
			
			.options {
                margin: 8px 0px 8px 0px;
                input {
                    margin-right: 10px;
                }
                label{
                    margin-bottom: 0px;
                    font-size: 15px;
                    .count {
                        color: #afabab;
                        font-size: 14px;
                    }
                }
            }
		}
	  }
	}
    }
  
    .col-grid-box {
      width: 100%;
      background: #fff;
      padding: 0px 10px 0px 10px;
    }
  
    .sortby {
      .input-group-text {
        background-color: #fff;
        border-radius: 0;
        border-right: 0;
      }
      .sortby-options {
        border-left: 0px;
        border-radius: 0;
      }
    }
  }
  /** Product Details**/
  
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid #f7f1f1;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid #f7f1f1;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 24px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: #fff;
								border-left:0;
								color:$primary-color;
							}
						}
						
                    }
                   
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px!important;
		height: 20px!important;
		top: -8px!important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px!important;
		box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
	}

	 .ng5-slider-span:focus{
		outline: 0;
	}
	.ng5-slider-pointer:after {
		display: none !important;
	}
	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
  .owl-theme {
    .owl-dots { 
      position: absolute;
			bottom: 15px;
			left: 50%;
	}
	.owl-next,
	.owl-prev {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background-color: $theme-bg !important;
		border: 0px !important;
		.fa {
			font-size: 30px;
		}
	}
	.owl-next{
		right:0;
		background:$theme-bg;
		width:20px;
	}
	.owl-prev{
		left:0;
		background:$theme-bg;
	}
  }
}
.rounder-corner-nav {
	&.top-nav-right{
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
	}
}
&.top-nav-bg-white{
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
					background: #fff;
					border: 0px;
					.fa {
						color:#000;
						
					}
			}
		}
}
}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;
				.fa {
					color:#b7b6b5;
					font-size: 22px;
				}
			}
		}
	}
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid $primary-color;
					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
  .tab-image {
          min-height: 300px;
          text-align: center;
          position: relative;
          margin-bottom: 30px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          .background-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
                min-height: 300px;
                &:hover {
                  transform: scale(1.2);
                }
          }
          .bg-content{
            position: absolute;
            top:50%;
            left:50%;
			transform: translate(-50%, -50%);
			 color:#fff;
            font-size: 25px;
           
			  p{
				  font-size: 15px;

			  }
           
          }
          
  }
}


.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
  .title {
	  text-align: center;
	  padding-bottom: 20px;
	   h2 {
		// font-family: 'proxima-nova';
	}
  }
  .column {
      
      -webkit-flex: 1; /* Safari */
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* Standard syntax */
      
      &.grid-image {
          -webkit-flex: 2; /* Safari */
          -ms-flex: 2; /* IE 10 */
          flex: 2; /* Standard syntax */
      }
      &.single-image {
          border: 1px solid #c5c5c5;
          margin-bottom:20px;
          text-align: center;
          .trers-single-image {
              img {
                  min-width: 300px;
              }
          }
          h4 {
              font-size: 20px;
              text-transform: uppercase;
          }
      }
      .tyers-grid {
          display: flex;
          flex-flow: wrap;
          margin: 0;
          li {
              width:calc(50% - 20px);
              border: 1px solid #c5c5c5;
              margin: 0px 20px 20px 0px;
              padding:10px;
              display: flex;
			  align-items: center;
			  .trers-grid-image {
				  img {
					  padding: 10px;
				  }
			  }
              a {
				cursor: pointer;
				// display:block;
              }
              &:hover {
                border-color: var(--theme-deafult);
              }
              h4 {
                  font-size: 20px;
                  text-transform: uppercase;
              }

          }
      }
    
      
  }
 
}
.mobile-cart{
	display: none !important;
}

.mobile-only{
	display: none !important;
}
.home-featured-collections-title{
	font-size: 1.4rem; 
	text-transform: uppercase; 
	font-weight: bold;
	color:black;
	text-align: center;

}

@media screen and (max-width:600px) {

	.featured-collections {
		.tab-image {
				height:100px;
				text-align: center;
				position: relative;
				margin-bottom: 30px;
				cursor: pointer;
				overflow: hidden;
				position: relative;
				.background-image {
					position: relative !important;
				  min-height: 100px;
				  width: 120%;
				  margin-left: -10%;
				 
				  background-repeat: no-repeat;
				  -webkit-transition: all .5s;
				  -moz-transition: all .5s;
				  -o-transition: all .5s;
				  transition: all .5s;
					  &:hover {
						transform: scale(1.2);
					  }
				}
				.bg-content{
				  position: absolute;
				  top:50%;
				  left:50%;
				  transform: translate(-50%, -50%);
				   color:#fff;
				  font-size: 25px;
				 
					p{
						font-size: 15px;
					}
				 
				}
				
		}
	  }

	  .rounder-corner-nav {
		&.top-nav-right{
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
		}
	}
	&.top-nav-bg-white{
		.owl-theme {
			background-color: transparent !important;
			.owl-nav {
				margin-top:0px;
				background-color: transparent !important;
				.owl-prev,
				.owl-next {
						background: transparent !important;
						border: 0px;
						.fa {
							color:#000;
							
						}
				}
			}
	}
	}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			z-index: -100;
			position: absolute;
			top:45%;
			display: flex;
			width: 100%;
			text-align: center;
			justify-content: space-evenly;
			top:45%;
			transform: translate(6px, -34px);
			
			.owl-next {				
				position: relative;
				margin-left: 84%;
				width: 40px;
				height: 40px;
				background: transparent;
				border: none !important;
				background-color: transparent !important;
				.fa {
					color:black;
					font-size: 40px;
				}
			}
			.owl-prev{
				position: relative;
				margin-left: -4%;	
				width: 40px;
				height: 40px;
				background: transparent;
				border: none !important;
				background-color: transparent !important;
				.fa {
					color:black;
					font-size: 40px;
				}
			}
		}
	}
	}

	.mobile-cart{
		display: flex !important;
		float:right;
	}

	.desktop-carousel{
		display: none !important;
	}
	.desktop-only{
		display: none !important;
	}
	.mobile-only{
		display: block !important;
	}
	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
	.forgot-link-checkout{
		margin-top:-45px !important
	}
	.back-btn{
		width:35% !important;
		margin-left:65%;
	}
	
	.mob-logo{
		transform: scale(0.75);
        top:8px !important;
        left: 25%;
        position:fixed  ;
        z-index:3;
	}
	.home-featured-collections-title{
		font-size: 1rem; 
	}
}
@media (max-width: 1920px)  {
    .bestgifts-arrival{
      justify-content: center;
   } 
}

@media (min-width: 360px) and (max-width: 600px) {
    .bestgifts-arrival{
      justify-content: left;
   }
}


@media (max-width: 1920px)  {
	.bestgifts-banner{
		height: 45vh; 
		border: 0px solid white;
		width: 100%;
		background-size: cover;
		color: white;
   }

   .thumb1-avon {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-left: 20px
  }
   
  }

  @media (min-width: 360px) and (max-width: 600px) {
	.bestgifts-banner{
		height: 25vh; 
		border: 0px solid white;
		width: 100%;
		background-size: cover;
		color: white;
   }

   .thumb1-avon{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  
  }


  .product-list-avon{
    border:none;
    // width: 100%!important;
    min-width: 225px;
    max-width:300px;
    min-height: 300px;
    max-height:400px;
    padding-top: 10px;

}

@media (min-width: 640px) {
	.product-list-avon{
		border:none;
		// width: 100%!important;
		min-width: 200px;
		max-width:300px;
		min-height: 200px;
		max-height:389px;
		padding-top: 10px;
		transform: translate(73px, 4px);
  }
  .product-list-avon1{
	border:none;
	// width: 100%!important;
	min-width: 200px;
	max-width:300px;
	min-height: 200px;
	max-height:389px;
	padding-top: 10px;
	transform: translate(30px, 2px);
}
  .product-list-image-avon{
	min-height: 150px;
    max-height: 180px;
	width: 100% ;
	// width: 60% !important;
	border-radius: 10px ;
	object-fit: contain;
	
  }

	}

.avon-img{
	text-align: center;
  }

  .product-list-image-avon{
	min-height: 210px ;
	max-height: 210px ;
	width: 100% ;
	// width: 60% !important;
	border-radius: 10px ;
	object-fit: contain;
	
  }

  .product-list-description-avon{
	padding: 10px 15px;
	margin-bottom: 10px;
  }


  .product-list-tile-avon{
	font-size: 16px !important;
	color: #c50 !important;
	font-weight: 700 !important;
	overflow: hidden !important;
	display: -webkit-box !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	text-overflow: ellipsis !important;
	background-clip: text;
	-webkit-background-clip: text;
	display: inline-block;
  }
  @media (max-width: 640px) {
  .product-list-avon1{
	border:none;
	// width: 100%!important;
	min-width: 200px;
	max-width:300px;
	min-height: 200px;
	max-height:389px;
	padding-top: 10px;
	transform: translate(6px, 2px);
}
  }

