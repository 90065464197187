@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}
  }
  
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media (min-width: 991px) {
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }

  		
  @media (max-width: 640px) {
	.special-checkout{display: flex;
		justify-content: center;
		width: 109px;}
	
.carousel-inner{
				margin-top: 20px;
			}
			.product-list-image-avonseals1{
				min-height: 190px;
				max-height: 275px;
				margin-left: 17px;
				width: 88%!important;
				border-radius: 10px;
			}
		}
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	//   padding-top: 47px;
	  padding-top: 0px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 100%;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
	  }
	  
	  .dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	  }
	  
	  .dropdown-content a:hover {
		background-color: #ddd;
	  }
	  
	  .dropdown:hover .dropdown-content {
		display: block;
	  }
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		//   padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {

	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 150px !important;
		margin-top: 16%;
	}
	
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }


  @media (min-width: 1300px){
	section .star{
		width: 100%;
		}
  }


@media (max-width: 640px) {
	section .star{
		width:350px;
		margin: 10px;
		.review{
			width: 308px;
			transform: translate(2px, 17px);
		}
	}
}

@media (min-width: 1300px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	footer{
        .soc-icons{
            display: flex;
        }
    }
}

@media (max-width: 640px) {
	.slider{
		display:flex;
		width: 94%;
		transform: translate(10px, -10px);
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}

footer{
        .soc-icons {
            display: grid !important;
			margin-left: -10px;
			
        
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
			
			.social-icons1{
			max-width: 370px;
			}
		}
	}
    }
}

@media (max-width: 640px) {
	.avon_line{
		border-left: 3px solid black;
		height: 26px;
	}
	.avon-footer-cont{
		padding: 0px;
		margin: 0px;
	}
	.avon1-img{
		width: 59%;
	}
	.avonbanner{
		object-fit: contain;
		width:100%
		}
		.dropdown{
			width: 100%;
		}
.cartscr{
				display: block;
			}
			.avonhomeimage{
				width: 100%; height: 98%; object-fit: contain;
			}
			.avon_prodname{
				width: 55%; 
				margin-left: 21%; 
				margin-bottom: 25px;
			}
			
	}
	
	@media (min-width: 640px) {
		.avon_line{
			border-left: 3px solid black;
			height: 35px;
		}
		.avon_prodname{
			width: 50%; 
			margin-left: 37%; 
			margin-bottom: 25px;
		}
		.cartscr{
			
			width: 100%;
		}
		.listdetail{
			width: 50%;
		}
		.dropdown{
			width: 153%;
		}
	.avonbanner{
		margin-top: 2px;
		margin-left: 20px;
		border: 2px solid rgb(144, 140, 140);
		width: 97%;
		
		color: white;
		object-fit: cover;
		height: 49vh;
		}

		.avonhomeimage{
			width: 127%; height: 98%; object-fit: contain;
		}
	}


	@media (min-width: 640px) {
		.vertical-line{
				display: flex;
				justify-content: center;
				
			}
		.home-prod{
				
			.home-prod1{
				height: 200px;
				width: 275px;
				.prod-image{
					height: 148px;
					object-fit: contain;
					width:100% !important
				}
			}
		}
		}
		
		@media (max-width: 640px) {
		.vertical-line{
				margin-top: 20px;
			}
		.home-container4{
		
				width: 190px;
			}
			.home-prod{
				height: 140px;
				width: 195px;
				.home-prod1{
					height: 140px;
					width: 190px;
					.prod-image{
						height: 88px;
					}
					.home-prod-title{
						width: 130px;
						font-size: 15px;
					}
				}
			}

			.product-list-avon{
				margin-left: 20px;
			}
		}


		@media (max-width: 640px) {
			.row1{
					height: 300px;
					transform: translate(0px, 24px);
				}
			}

			@media (min-width: 640px) {
				.footer-col1{
					
					justify-content: center !important;
				padding: 5px;
				margin:5px;
				margin-left: 300px;
				.social-icons1{
				max-width: 370px;
				
				}
				}
				}
				
				@media (max-width: 640px) {
				.footer-col1{
								justify-content: center !important;
							padding: 5px;
							margin:5px;
							min-width:136%;
							
							
							}
							.avon-checkout{
								padding-left: 16px !important
							}
							.social-icons3{
								height: 50px; 
								display: flex; 
								justify-content: space-around !important;
    							/* margin-left: -19px; */
    							width: 300px;
							}
							.social-icons2{
								max-width: 370px;
								margin-bottom: 0rem !important;
								}
				}


				@media (min-width: 640px) {
					.avon-chart{
						width: -webkit-fill-available;
						
					}
					.social-icons3{
						height: 50px; 
						display: flex; 
						justify-content:flex-start;
					}
				}


				@media (max-width: 640px) {
					.avon-chart{
						width: 100%;
						
					}
					.home-prod-sample{
						.home-prod1-sample{
							height: 100px;
							width: 70px;
							.prod-image{
								height: 148px;
								object-fit: contain;
								width:100% !important
							}
						}
					}
				.product-list-avon{
						margin-left: 20px;
						
					}
				}
				
				@media (min-width: 640px) {
				.home-prod-sample{
					.home-prod1-sample{
						height: 100px;
						width: 175px;
						.prod-image{
							height: 148px;
							object-fit: contain;
							width:100% !important
						}
					}
				}
				.product-list-avon{
						margin-left: 0px;
						
					}
				}

				@media (min-width: 640px) {
					.carousel-inner{
								margin-top: -10px;
								background-color: white;
							}
					}
					
					@media (max-width: 640px) {
					.carousel-inner{
									margin-top: 20px;
								}
							}
					
					@media (min-width: 640px) {
									.footer-social-icons{
										display: flex;
									.footer-col1{
										
										justify-content: center !important;
									padding: 5px;
									margin-left: auto;
									.social-icons1{
									max-width: 500px;
									
									}
									}
									}
								}
									@media (max-width: 640px) {
										.footer-social-icons{
											display: block;
									.footer-col1{
													justify-content: center !important;
												padding: 5px;
												margin:5px;
												min-width:136%;
												
												.social-icons1{
												max-width: 350px;
												margin-left: 0px;
												}
												}
									}
									.social-icon3{
										width: 70%;
										font-size: 12px;
   										color: #f0f8ff;
									}
								}

								@media (min-width: 640px) {
									.avon_cont2{
										text-shadow: none !important;
										font-family: 'proxima-regular','sans-serif'!important; 
										color: #373230 !important;
										padding-top: 0px; 
										margin-top: -15px;
										font-size: 33px;
										font-weight: 900;
									}
									.avon_cont{
										font-size: 25px!important;
									}
									.avon-title3{
										display: grid;
										justify-content: center;
										margin-left: 38px;
									}
									.social-icon3{
										font-size: 12px;
										color: aliceblue ;
									}
									.content-title2{
												text-shadow: none!important;
												font-family: proxima-regular,"sans-serif"!important;
												color: #373230!important;
												font-size: 40px!important;
												padding-top: 0!important;
												margin-top: 8px!important;
												font-size: 50px!important;
												font-weight: 700!important;
												max-width: 105%!important;
											}
									.product-title{
										margin-left: 2%;
										width: 80%;
									}
									.slider1{
												width: 94%;
												margin-left: 2%;
												display: flex;
											}
									}
									@media (max-width: 640px) {
										.avon_cont2{

											text-shadow: none !important;
											font-family: 'proxima-regular','sans-serif'!important; 
											color: #373230 !important;
											padding-top: 0px; 
											margin-top: -20px !important;
											font-size: 30px !important;
											font-weight: 900;
										}
										.avon_cont{
											font-size: 22px!important;
											margin-bottom: 10px !important;
										}
										.avon-title3{
											
											margin-left: 0px;
										}
									.content-title2{
										text-align: center;
											text-shadow: none!important;
												font-family: proxima-regular,"sans-serif"!important;
												color: #373230!important;
												
												padding-top: 0!important;
												margin-top: 8px!important;
												font-size: 30px!important;
												font-weight: 700!important;
												max-width: 105%!important;
												margin-bottom: 10px !important;
										}
									.slider1{
											width: 97%;
											margin-left: 0%;
											display: flex;
										}
									.slider2{
											display:flex;
											width: 88%;
											transform: translate(15px, -10px);
										}
									.row3{
									  transform: translate(26px,0px);
									}
									.product-title{
										transform: translate(-12px, 0px);
									}
									.product-list-description-avon{
										margin-left: px;
									}
									.d-flex1{
										margin-left: 37%;
									}
									}
									@media (min-width: 640px) {
										.d-flex1{
											width: 50%;
											margin-left: 37%;
											margin-bottom: 25px;
										}
								}
								.logo-images{
									object-fit: contain;
								}

								.avonHome{
									display: flex!important;
								}

								.avonHome1{
									display: none!important;
								}

								.avonHome2{
									display: none!important;
								}

								.avonHome3{
									display: none!important;
								}

								@media only screen and (min-device-width: 864px) and (max-device-width: 1536px) {
									.avonHome{
										display: none!important;
									}
	
									.avonHome1{
										display: flex!important;
									}
									.avonHome2{
										display: none!important;
									}
									.avonHome3{
										display: none!important;
									}
							  }

							  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
								.avonHome{
									display: none!important;
								}

								.avonHome1{
									display: none!important;
								}

								.avonHome2{
									display: flex!important;
								}
								.avonHome3{
									display: none!important;
								}
						  }

						  @media only screen and (min-device-width: 900px) and (max-device-width: 1440px) {
							.avonHome{
								display: none!important;
							}

							.avonHome1{
								display: none!important;
							}

							.avonHome2{
								display: none!important;
							}

							.avonHome3{
								display: flex!important;
							}
					  }
								
							// 	@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
							// 		.avon_title {
							// 		  display: none;
							// 		}
							// 	  .avon_title1 {
							// 		  display: block;
							// 		}
							//   }

							//   @media (max-width: 991px) and (min-width: 640px) {
							// 	.avon_title {
							// 		display: block;
							// 	  }
							// 	.avon_title1 {
							// 		display: none;
							// 	  }
							//   }

							//   @media screen and (max-width: 600px) {
							// 	.section_title {
							// 		display: block;
							// 	  }
							// 	.section_title1 {
							// 		display: none;
							// 	  }
							// }