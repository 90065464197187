@import "../../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.account {
    li {
        a {
            .lnr {
                position:relative
                .count {
                    position: absolute;
                }
            }
        }
    }
}

ul.account li a span.lnr {
    position: relative;
    font-size: 18px;
}

ul.account li a span .count {
    // position: absolute;
    // background: #FBCB0C;
    // width: 18px;
    // height: 18px;
    // border-radius: 9px;
    // top: -3px;
    // right: 40px;
    position: absolute;
  background: #FBCB0C;
  width: 20px;
  height: 20px;
  border-radius: 9px;
  top: -5px;
  right: 40px;
}

span.count-number {
    // color: #fff;
    // position: absolute;
    // left: 0.2rem;
    // font-size: 14px;
    // font-weight: bold;
    color: #fff;
  position: absolute;
  left: .2rem;
  font-size: 14px;
  font-weight: 700;
  top: .2rem;
}

.menu-title {
    // margin-left: 10px;
    font-weight: 500 !important;
}

.sub-items{
    z-index: 10000;
    display: block;
}

.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: 2px solid #ddd;
    width: 100%;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 98%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
        max-height: 300px;
      }
  }
  .autocomplete-items li {
    font-size: 13px !important;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    // border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items li:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }
  .autocomplete-items3 {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: 2px solid #ddd;
    // margin-left: 15%;
    margin-left: 18px;
    width: 91%;
    border-top: none;
    z-index: 99;
    font-size: 13px !important;
    /*position the autocomplete items to be the same width as the container:*/
    top: 98%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
        max-height: 300px;
        margin-left: 130px;
      }
      @include media-breakpoint-down(xs) {
        max-height: 300px;
        margin-left: 0px !important;
        width:69%;
      }
  }

  .autocomplete-items1 {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: 2px solid #ddd;
    // margin-left: 15%;
    margin-left: 160px;
    width: inherit;
    border-top: none;
    z-index: 99;
    font-size: 13px !important;
    /*position the autocomplete items to be the same width as the container:*/
    top: 98%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
        max-height: 300px;
        margin-left: 130px;
      }
      @include media-breakpoint-down(xs) {
        max-height: 300px;
        margin-left: 0px !important;
        width:69%;
      }
  }
  .autocomplete-items1 li {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    font-size: 13px !important;
    // border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items1 li:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-items3 li {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    font-size: 13px !important;
    // border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items3 li:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active1 {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }

  .autocomplete-items2 {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: 2px solid #ddd;
    // margin-left: 15%;
    margin-left: 0px;
    width: 90%;
    border-top: none;
    z-index: 99;
    font-size: 13px !important;
    /*position the autocomplete items to be the same width as the container:*/
    top: 98%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
        max-height: 300px;
        margin-left: 130px;
      }
      @include media-breakpoint-down(xs) {
        max-height: 300px;
        margin-left: 110px;
      }
  }
  .autocomplete-items2 li {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    font-size: 13px !important;
    // border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items2 li:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }

  .input-group{
      line-height: 1rem !important;
  }

  .search-input{
      margin-left: 80px;
      margin-right: 50px;
      font-size: 14px !important;
      @include media-breakpoint-down(sm) {
        margin-left: 0px;
        margin-right: 0px;
      }
  }
  .search-input-aiema{
    margin-left: 80px;
    margin-right: 50px;
    font-size: 14px !important;
    width:90%;
    @include media-breakpoint-down(sm) {
      margin-left: 0px;
      margin-right: 0px;
    }
}

  .search-drop{
    line-height: 1rem !important;
    background-color: $primary !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: 2px;
    border-color: #E1E7EC;
    
    border-right: none;
    font-size: 14px !important;
    width: 160px;
    color : #fff !important;
    @include media-breakpoint-down(sm) {
      width: 130px;
    }
    @include media-breakpoint-down(xs) {
      width: 110px;
      display: none !important;
      font-size: 12px !important;
    }
  }

  .search-form-input{
      border-left: none !important;
      border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;

      font-size: 16px;
      // text-transform: lowercase;
      // @include media-breakpoint-down(xs) {
      //   font-size: 12px !important;
      // }
  }
  @media screen and (max-width: 600px) {
    .search-form-input {
        max-width: 60%
    }
    .search-input-aiema{
      width:120% !important;
  }
}

  // .search-form-input:first-line {
  //   text-transform: capitalize;
  // }

  .logout-cutom{
    color: #007bff;
  }

  .form-control:focus{
    border-color: #E1E7EC !important;
    // border-left: none !important;
  }

  // .form-control{
    // border-left: none !important;
    // border-bottom-left-radius: 0px !important;
    // border-top-left-radius: 0px !important;
  // }

  .dropdown-item{
    font-size: 13px !important;
  }

  .titleproper{
    text-transform: lowercase;
  }

  .titleproper:first-line {
    text-transform: capitalize;
  }

  .sticky-header {
    @include media-breakpoint-up(sm) {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 99;
    }    
  }

  .header {
    background: #fff;
    border-bottom: 2px solid #ddd;
    .logo{
      max-width:200px;
    }
    .flex-container {
      align-items: center;
      display: flex !important;
      padding: 15px 2px 15px 2px;
      .header-row-one {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .account-section{
          position: relative;
        }
        .account {
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            padding: 6px 10px;
            &.sell {
              border: 2px solid #00000033;
              border-radius: 5px;
            }
            &.loginuser {
              .myaccount {
                display: flex;
                align-items: center;
                
                .menu-title {
                  font-size: 14px;
                }
  
              }
            }
            &.language {
              .form-control {
                border: none;
                height: 100%;
                &:focus {
                  border-color: transparent;
                  border: none;
                  box-shadow: none;
                }
              }
            }
          }
          .profile-links {
            li {
              padding: 0px;
              border-bottom: 1px solid black;
              a {
                // font-family: 'Inter-Regular';
                font-size: 14px;
                color:#404B59;
                padding:6px 15px;
                display: block;
                i{
                  margin-right: 10px;
                    font-size: 17px;
                }
              }
            }
          }
        }
  
      .search-bar-group{
        flex: 1;
        .search-bar {
          margin-left: 10px !important;
          .input-group {
            input {
              background: $primary;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border-width: 2px;
              border-color:  #E1E7EC;
              border-left: 1px solid #ccc !important;
              &:hover{
                border-color: $primary;
              }
            }
            p {
              margin: 0px 30px 0px 0px;
              font-size: 12px;
              color: #808080;
              float: left;
              span {
                font-size: 14px;
                color: #000000;
              }
            }
            .input-group-btn {
              .btn-solid {
                color: #fff;
                border-radius: 0 0.25rem 0.25rem 0;
                border-left: 0px;
                background-color: $primary;
              }
            }
          }
        }
      }
      }
    }
  }

  .linenraj-header {
    // background: #fff;
    // border-bottom: 2px solid #ddd;
    .logo{
      max-width:200px;
    }
    .flex-container {
      align-items: center;
      display: flex !important;
      padding: 15px 2px 15px 2px;
      .header-row-one {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .account-section{
          position: relative;
        }
        .account {
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            padding: 6px 10px;
            &.sell {
              border: 2px solid #00000033;
              border-radius: 5px;
            }
            &.loginuser {
              .myaccount {
                display: flex;
                align-items: center;
                
                .menu-title {
                  font-size: 14px;
                }
  
              }
            }
            &.language {
              .form-control {
                border: none;
                height: 100%;
                &:focus {
                  border-color: transparent;
                  border: none;
                  box-shadow: none;
                }
              }
            }
          }
          .profile-links {
            li {
              padding: 0px;
              border-bottom: 1px solid black;
              a {
                // font-family: 'Inter-Regular';
                font-size: 14px;
                color:#404B59;
                padding:6px 15px;
                display: block;
                i{
                  margin-right: 10px;
                    font-size: 17px;
                }
              }
            }
          }
        }
  
      .search-bar-group{
        flex: 1;
        .search-bar {
          margin-left: 10px !important;
          .input-group {
            input {
              background: $primary;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border-width: 2px;
              border-color:  #E1E7EC;
              border-left: 1px solid #ccc !important;
              &:hover{
                border-color: $primary;
              }
            }
            p {
              margin: 0px 30px 0px 0px;
              font-size: 12px;
              color: #808080;
              float: left;
              span {
                font-size: 14px;
                color: #000000;
              }
            }
            .input-group-btn {
              .btn-solid {
                color: #fff;
                border-radius: 0 0.25rem 0.25rem 0;
                border-left: 0px;
                background-color: $primary;
              }
            }
          }
        }
      }
      }
    }
  }

  @media (max-width: 1920px)  {
    .bestgift-menu{
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
    }
  }

  @media (min-width: 360px) and (max-width: 600px) {
    .bestgift-menu{
      flex-direction: column;
      box-sizing: border-box;
    }
  }